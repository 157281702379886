import classes from "./BlogStartup.module.css";

const BlogStartup = () => {
  return (
    <>
      <div className={classes.startupheading}>
        <h1>
          This publishing startup makes
          <br /> interactive Tamil boardbooks for children
        </h1>
      </div>
    </>
  );
};

export default BlogStartup;
